import {Component} from '@angular/core';
import {MenuTypes} from './components/main/enums/MenuTypes';
import {ExamDetailsComponent} from './components/exam-details/exam-details.component';
import {ExamDashboardComponent} from './components/exam-dashboard/exam-dashboard.component';
import {CreateExamComponent} from './components/create-exam/create-exam.component';
import {StudentDetailsComponent} from './components/student-details/student-details.component';
import {MainComponent} from './components/main/main.component';
import {EduMaterialComponent} from './components/edu-material/edu-material.component';
import { QuestionDetailsComponent } from './components/question-details/question-details.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vizenjo-admin';

  selectedMenu: MenuTypes = MenuTypes.People;
  topTitle = 'Felhasználók';

  constructor() { }

  adjustTitle(event): void {
    if (event instanceof ExamDetailsComponent || event instanceof ExamDashboardComponent) {
      this.selectedMenu = MenuTypes.Exams;
      this.topTitle = 'Vizsgák';
    } else if (event instanceof CreateExamComponent) {
      this.selectedMenu = MenuTypes.NewExam;
      this.topTitle = 'Új vizsga';
    } else if (event instanceof StudentDetailsComponent || event instanceof MainComponent) {
      this.selectedMenu = MenuTypes.People;
      this.topTitle = 'Felhasználók';
    }
    // else if (event instanceof EduMaterialComponent) {
    //   this.selectedMenu = MenuTypes.EduMaterial;
    //   this.topTitle = 'Tananyag';
    // }
    else if (event instanceof QuestionDetailsComponent) {
      this.selectedMenu = MenuTypes.Questions;
      this.topTitle = 'Kérdések';
    }
  }
}
