import { Component, OnInit } from '@angular/core';
import {ExamCalendar} from '../../network/models/ExamCalendar';
import {ExamRegistration} from '../../network/models/ExamRegistration';
import {ExamType} from '../../network/models/ExamType';
import {CalendarCourse} from '../../network/models/CalendarCourse';
import {Location} from '../../network/models/Location';
import {NetworkService} from '../../network/services/network.service';
import {NotificationService} from '../../notification/notification.service';
import {ModifyExamCalendar} from '../../network/models/ModifyExamCalendar';
import {NotificationType} from '../../notification/NotificationType';
import {Router} from '@angular/router';

@Component({
  selector: 'app-create-exam',
  templateUrl: './create-exam.component.html',
  styleUrls: ['./create-exam.component.scss']
})
export class CreateExamComponent implements OnInit {

  examCalendar: ExamCalendar;

  examTypes: ExamType[];
  courses: CalendarCourse[];
  examLocations: Location[];

  constructor(private router: Router, private network: NetworkService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.examCalendar = new ExamCalendar();

    this.network.getExamTypes().subscribe(examTypes => {
      this.examTypes = [];
      examTypes.forEach( examType => {
        this.examTypes.push({ codeId: examType.codeId, codeValue: examType.codeValue, description: examType.description });
      });
    });

    this.network.getCourses().subscribe( courses => {
      this.courses = [];
      courses.content.forEach(course => {
        this.courses.push({ courseId: course.courseId, courseName: course.courseName });
      });
    });

    this.network.getExamLocations().subscribe( locations => {
      this.examLocations = [];
      locations.forEach(location => {
        this.examLocations.push({ codeId: location.codeId, codeValue: location.codeValue, description: location.description });
      });
    });
  }

  onSubmit(): void {
    const examTypeCodeId =
      this.examTypes.find( examType => examType.codeValue === this.examCalendar.examTypeCodeVDTO.codeValue).codeId;
    const locationCodeId =
      this.examLocations.find( location => location.codeValue === this.examCalendar.locationCodeVDTO.codeValue).codeId;

    const newExamCalendar = new ModifyExamCalendar(
      this.examCalendar.courseDTO.courseId,
      examTypeCodeId,
      locationCodeId,
      this.examCalendar.startTime,
      this.examCalendar.endTime
    );

    this.network.createExamTime(newExamCalendar).subscribe( (examTime) => {
      this.router.navigate([ `/exams/${examTime.examCalendarId}` ]).then( () => {
        this.notificationService.sendMessage({
          message: 'Vizsga sikeresem létrehozva',
          type: NotificationType.success
        });
      });
    });
  }

  compareCourses(a, b): boolean {
    if (a && b) {
      return a.courseId === b.courseId;
    }
    return false;
  }

  compareTypes(a, b): boolean {
    if (a && b) {
      return a.codeValue === b.codeValue;
    }
    return false;
  }

  compareLocations(a, b): boolean {
    if (a && b) {
      return a.codeValue === b.codeValue;
    }
    return false;
  }

}
