import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {NotificationService} from '../../notification/notification.service';
import {NotificationType} from '../../notification/NotificationType';
import {DatePipe} from '@angular/common';
import {ExamCalendars} from '../models/ExamCalendars';
import {catchError, map} from 'rxjs/operators';
import {ExamCalendar} from '../models/ExamCalendar';
import {ExamRegistration} from '../models/ExamRegistration';
import {Courses} from '../models/Courses';
import {ExamType} from '../models/ExamType';
import {Location} from '../models/Location';
import {ModifyExamCalendar} from '../models/ModifyExamCalendar';
import {ActiveStudents} from '../models/ActiveStudents';
import {Document} from '../models/Document';
import {HierarchyTopic} from '../models/HierarchyTopic';
import {OAuth} from '../models/OAuth';
import {Chapter} from '../models/Chapter';
import {Question} from '../models/Question';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private notificationService: NotificationService, private datePipe: DatePipe) {
  }

  getExamTimes(page = 0, size = 1000): Observable<ExamCalendars> {
    return this
      .http.get<ExamCalendars>(`${this.baseUrl}/protected/exam_calendars?page=${page}&size=${size}`)
      .pipe(
        map((data) => {
          data.content.map((item) => {
            item.endTime = new Date(item.endTime);
            item.startTime = new Date(item.startTime);
            return item;
          });
          return data;
        }),
        catchError(this.handleError.bind(this))
      );
  }

  getExamTime(examCalendarId: number): Observable<ExamCalendar> {
    return this
      .http.get<ExamCalendar>(`${this.baseUrl}/admin/exam_calendar/${examCalendarId}`)
      .pipe(
        map((data) => {
          data.startTime = new Date(data.startTime);
          data.endTime = new Date(data.endTime);
          return data;
        }),
        catchError(this.handleError.bind(this))
      );
  }

  createExamTime(examCalendar: ModifyExamCalendar): Observable<ExamCalendar> {
    return this.http
      .post<ExamCalendar>(`${this.baseUrl}/admin/exam_calendar/new`, examCalendar)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  modifyExamTime(examCalendarId: number, examCalendar: ModifyExamCalendar): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/admin/exam_calendar/modify/${examCalendarId}`, examCalendar)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getExamRegistrations(examCalendarId: number): Observable<ExamRegistration[]> {
    return this
      .http.get<ExamRegistration[]>(`${this.baseUrl}/admin/exam_registration_list/${examCalendarId}`)
      .pipe(
        map((data) => {
          data.map((item) => {
            item.registrationDate = new Date(item.registrationDate);
            return item;
          });
          return data;
        }),
        catchError(this.handleError.bind(this))
      );
  }

  getCourses(): Observable<Courses> {
    return this
      .http.get<Courses>(`${this.baseUrl}/protected/courses`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getExamTypes(): Observable<ExamType[]> {
    return this
      .http.get<ExamType[]>(`${this.baseUrl}/protected/exam_types`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getExamLocations(): Observable<Location[]> {
    return this
      .http.get<Location[]>(`${this.baseUrl}/protected/exam_locations`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  acceptRegistration(examRegistrationId: number): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/admin/accept_exam_registration/${examRegistrationId}`, null)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  rejectRegistration(examRegistrationId: number): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/admin/cancel_exam_registration/${examRegistrationId}`, null)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getActiveStudents(page = 0, size = 10000): Observable<ActiveStudents> {
    return this
      .http.get<ActiveStudents>(`${this.baseUrl}/admin/active_students?page=${page}&size=${size}`)
      .pipe(
        map((data) => {
          data.content.map((item) => {
            item.studentDTO.registrationDate = new Date(item.studentDTO.registrationDate);
            return item;
          });
          return data;
        })
      );
  }

  getStudentDocuments(studentId: number): Observable<Document[]> {
    return this
      .http.get<Document[]>(
        `${this.baseUrl}/admin/student_document_list/${studentId}`
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getDocumentImage(studentDocumentId: number): Observable<Blob> {
    return this
      .http.get(
        `${this.baseUrl}/admin/student_documents/${studentDocumentId}`,
        { responseType: 'blob' }
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getTopicHierarchy(studentCourseId: number): Observable<HierarchyTopic[]> {
    return this
      .http.get<HierarchyTopic[]>(`${this.baseUrl}/admin/hierarchy/${studentCourseId}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getOAuthToken(token: string): Observable<OAuth> {
    return this.http.post<OAuth>(
      `${this.baseUrl}/public/authorize`,
      { token }
    ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getRefreshToken(token: string): Observable<OAuth> {
    return this.http.post<OAuth>(
      `${this.baseUrl}/public/refresh`,
      { token }
    ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getChapter(chapterId: number): Observable<Chapter> {
    return this
      .http.get<Chapter>(`${this.baseUrl}/protected/chapters/${chapterId}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getQuestion(questionId: number): Observable<Question> {
    return this
      .http.get<Question>(`${this.baseUrl}/protected/question/${questionId}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getImageResource(resourceId: number): Observable<Blob> {
    return this
      .http.get(
        `${this.baseUrl}/protected/resources/images/${resourceId}`,
        { responseType: 'blob' }
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // TODO http error handling
  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.notificationService.sendMessage({
        message: 'Hoppsz ez valahol elúszott! Kérjük próbálkozz később!',
        type: NotificationType.error
      });
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if (error.status === 401) {
        window.location.reload();
      } else {
        this.notificationService.sendMessage({
          message: 'Hoppsz ez valahol elúszott! Kérjük próbálkozz később!',
          type: NotificationType.error
        });
      }
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
