<!-- Image and text -->
<mdb-navbar SideClass="navbar fixed-top bg-white" [containerInside]="false">
  <mdb-navbar-brand>
    <a class="navbar-brand" href="#">
      <img src="../../../assets/images/header.svg" class="d-inline-block mr-4 ml-4" alt="">
    </a>
  </mdb-navbar-brand>
  <div class="navbar-text mr-auto">
      <span class="header-text">
        {{ topTitle }}
      </span>
    <br>
    <br>
    <span class="header-page-text">
        {{ pageTitle }}
    </span>
  </div>


  <ul class="navbar-nav mr-4 nav-flex-icons">
    <span class="exit-text" (click)="logout()">
      Kilépés
    </span>
  </ul>
</mdb-navbar>
