import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {NetworkService} from '../../network/services/network.service';
import {ExamCalendarTableRow} from '../../network/models/ExamCalendarTableRow';
import {MatSort} from '@angular/material/sort';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';

@Component({
  selector: 'app-exam-dashboard',
  templateUrl: './exam-dashboard.component.html',
  styleUrls: ['./exam-dashboard.component.scss']
})
export class ExamDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['courseName', 'courseType', 'location', 'startTime', 'endTime'];
  dataSource = new MatTableDataSource<ExamCalendarTableRow>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;
  inputSubscription: Subscription;

  constructor(private network: NetworkService) {
  }

  ngOnInit(): void {
    this.getExamTimes();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.inputSubscription = fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(350),
        distinctUntilChanged(),
        tap((text) => {
          this.applyFilter(this.input.nativeElement.value);
        })
      )
      .subscribe();
  }

  getExamTimes(): void {
    this.network.getExamTimes().subscribe( examTimes => {
      const tableRows = [];
      examTimes.content.forEach(examTime => {
        const tableRow = new ExamCalendarTableRow(
          examTime.courseDTO.courseName,
          examTime.examTypeCodeVDTO.description,
          examTime.locationCodeVDTO.description,
          examTime.startTime,
          examTime.endTime,
          examTime.examCalendarId,
          examTime.courseDTO.courseId);
        tableRows.push(tableRow);
      });
      this.dataSource.data = tableRows;
    });
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  ngOnDestroy(): void {
    this.inputSubscription.unsubscribe();
  }

}
