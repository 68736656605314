import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {NetworkService} from '../../network/services/network.service';

@Component({
  selector: 'app-edu-material',
  templateUrl: './edu-material.component.html',
  styleUrls: ['./edu-material.component.scss']
})
export class EduMaterialComponent implements OnInit {

  constructor(private network: NetworkService) { }

  public Editor = ClassicEditor;
  editorData = '<p>Hello, world!</p>';

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      // ['bold', 'italic'],
      // ['fontSize']
    ]
  };

  ngOnInit(): void {
    this.network.getChapter(5).subscribe(chapter => {
      this.editorData = chapter.textBody;
    });
  }
}
