<div class="w-100 text-right pr-2">
  <input type="text" placeholder="Keresés" #input>
</div>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">

    <ng-container matColumnDef="courseName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kurzus </th>
      <td mat-cell *matCellDef="let element"> {{element.courseName}} </td>
    </ng-container>

    <ng-container matColumnDef="courseType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kurzus típusa </th>
      <td mat-cell *matCellDef="let element"> {{element.courseType}} </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Helyszín </th>
      <td mat-cell *matCellDef="let element"> {{element.location}} </td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kezdés </th>
      <td mat-cell *matCellDef="let element"> {{element.startTime | date:'yyyy.MM.dd HH:mm' }} </td>
    </ng-container>

    <ng-container matColumnDef="endTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Vége </th>
      <td mat-cell *matCellDef="let element"> {{element.endTime | date:'yyyy.MM.dd HH:mm' }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="vizenjo-row" routerLink="/exams/{{ row.examCalendarId }}"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
</div>
