import {OwlDateTimeIntl} from 'ng-pick-datetime';
import {Injectable} from '@angular/core';

@Injectable()
export class MyOwlDateTimeIntl extends OwlDateTimeIntl {
  /** A label for the cancel button */
  cancelBtnLabel = 'Kilépés';

  /** A label for the set button */
  setBtnLabel = 'Beállítás';
}
