<app-navbar [topTitle]="'VízenJÓ admin felület'" [pageTitle]="topTitle">
</app-navbar>
<mat-sidenav-container class="example-container">
  <mat-sidenav mode="side" opened>
    <app-side-nav [selected]="selectedMenu">
    </app-side-nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet (activate)="adjustTitle($event)"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
