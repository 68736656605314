<div class="main-container">
  <form (ngSubmit)="submit()" #questionForm="ngForm">
    <div class="w-100 text-right pr-2">
      <input type="text" placeholder="Kérdés azonosítója" name="idInput" #idInput="ngModel"  [(ngModel)]="questionId" >
      <button type="submit" class="btn btn-success mr-4">Keresés</button>
    </div>
  </form>
  <div *ngIf="question" class="pt-3">
    <div class="row">
      <div class="col-6">
        <div class="pb-2"><b>Kérdés</b></div>
        <p #questionDesc>{{ question.description }}</p>
        <ng-container *ngIf="imageToShow">
          <div class="d-lg w-100 text-center mt-4">
            <img class="help-image" [src]="imageToShow" alt="help image">
          </div>
        </ng-container>
      </div>
      <div class="col-6">
        <div class="pb-2"><b>Válaszok</b></div>
        <p *ngFor="let item of question.answers">
          {{ item.description }}
          <b>{{ item.correctAnswerFl ? ' (Helyes válasz)' : '' }}</b>
        </p>
      </div>
    </div>
  </div>
</div>
