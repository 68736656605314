import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NetworkService} from '../../network/services/network.service';
import {ActiveStudent} from '../../network/models/ActiveStudent';
import {ActivatedRoute} from '@angular/router';
import {DocumentTypes} from './enums/DocumentTypes';
import {Document} from '../../network/models/Document';
import {MatTableDataSource} from '@angular/material/table';
import {HierarchyTopicTableRow} from '../../network/models/HierarchyTopicTableRow';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.scss']
})
export class StudentDetailsComponent implements OnInit, AfterViewInit {

  student: ActiveStudent;

  idCard: Document = null;
  idCardPicture: any;
  addressCard: Document = null;
  addressCardPicture: any;

  progressDisplayedColumns: string[] = ['topicName', 'learnedPercent', 'numOfQuestions', 'numOfLearnedQuestions'];
  progressDataSource = new MatTableDataSource<HierarchyTopicTableRow>();
  allowedToTakeExam = false;

  examId: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private network: NetworkService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    const studentId = +this.route.snapshot.params.id;
    this.examId = this.route.snapshot.queryParams.examId;

    this.network.getActiveStudents().subscribe( students => {
      this.student = students.content.find(student => student.studentDTO.studentId === studentId);
      if (this.student) {
        this.getStudentDocuments(this.student.studentDTO.studentId);
        this.getProgress(this.student.studentCourseId);
      }
    });
  }

  ngAfterViewInit(): void {
    this.progressDataSource.paginator = this.paginator;
    this.progressDataSource.sort = this.sort;
  }

  getStudentDocuments(studentId: number): void {
    this.network.getStudentDocuments(studentId).subscribe(
      documents => {
        documents.forEach(document => {
          if (document.documentType.codeValue === DocumentTypes.IdCard) {
            this.idCard = document;
            this.network.getDocumentImage(this.idCard.studentDocumentId).subscribe(
            doc => {
              this.createImageFromBlob(doc, DocumentTypes.IdCard);
            });
          } else if (document.documentType.codeValue === DocumentTypes.AddressCard) {
            this.addressCard = document;
            this.network.getDocumentImage(this.addressCard.studentDocumentId).subscribe(
            doc => {
              this.createImageFromBlob(doc, DocumentTypes.AddressCard);
            });
          }
        });
      });
  }

  createImageFromBlob(image: Blob, type: DocumentTypes): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (type === DocumentTypes.IdCard) {
        this.idCardPicture = reader.result;
      }
      else if (type === DocumentTypes.AddressCard) {
        this.addressCardPicture = reader.result;
      }
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getProgress(studentCourseId: number): void {
    this.network.getTopicHierarchy(studentCourseId).subscribe( topics => {
      this.allowedToTakeExam = true;
      const tableRows = [];
      topics.forEach(topic => {
        if (topic.learnedPercent < 80) {
          this.allowedToTakeExam = false;
        }
        const tableRow = new HierarchyTopicTableRow(
          topic.topicName,
          topic.learnedPercent,
          topic.numOfQuestions,
          topic.numOfLearnedQuestions
        );
        tableRows.push(tableRow);
      });
      this.progressDataSource.data = tableRows;
      if (!this.progressDataSource.data) {
        this.allowedToTakeExam = false;
      }
    });
  }
}
