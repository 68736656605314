export class ExamCalendarTableRow {
  constructor(private courseName: string,
              private courseType: string,
              private location: string,
              private startTime: Date,
              private endTime: Date,
              private examCalendarId: number,
              private courseId: number){
  }
}
