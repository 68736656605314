import {Component, Input, OnInit} from '@angular/core';
import { faUsers, faCalendarAlt, faCalendarPlus, faBook, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import {MenuTypes} from '../main/enums/MenuTypes';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @Input() selected: MenuTypes;
  menuTypes = MenuTypes;

  faUsers = faUsers;
  faCalendar = faCalendarAlt;
  faCalendarPlus = faCalendarPlus;
  faBook = faBook;
  faClipboardList = faClipboardList;

  constructor() { }

  ngOnInit(): void {
  }

}
