<div class="w-100 text-right pr-2">
  <input type="text" placeholder="Keresés" #input>
</div>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">

    <ng-container matColumnDef="studentName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Név </th>
      <td mat-cell *matCellDef="let element"> {{element.studentName}} </td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.username}} </td>
    </ng-container>

    <ng-container matColumnDef="registrationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Regisztráció </th>
      <td mat-cell *matCellDef="let element"> {{element.registrationDate | date:'yyyy.MM.dd HH:mm' }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="vizenjo-row" routerLink="/students/{{ row.studentId }}"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
</div>

