import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let activation = true;
    this.auth.isLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.router.navigate(['vizenjo']);
        activation = false;
      }
    });
    return activation;
  }
}
