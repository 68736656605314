<div class="side-nav-container">
  <div class="menu-item" routerLink="/" [ngClass]="selected === menuTypes.People ? 'selected-menu' : ''">
    <fa-icon [icon]="faUsers" size="3x"></fa-icon>
    <div class="mt-2">
      Felhasználók
    </div>
  </div>
  <div class="menu-item" routerLink="/exams" [ngClass]="selected === menuTypes.Exams ? 'selected-menu' : ''">
    <fa-icon [icon]="faCalendar" size="3x"></fa-icon>
    <div class="mt-2">
      Vizsgák
    </div>
  </div>
  <div class="menu-item" routerLink="/new-exam" [ngClass]="selected === menuTypes.NewExam ? 'selected-menu' : ''">
    <fa-icon [icon]="faCalendarPlus" size="3x"></fa-icon>
    <div class="mt-2">
      Új vizsga
    </div>
  </div>
  <div class="menu-item" routerLink="/questions" [ngClass]="selected === menuTypes.Questions ? 'selected-menu' : ''">
    <fa-icon [icon]="faClipboardList" size="3x"></fa-icon>
    <div class="mt-2">
      Kérdések
    </div>
  </div>
</div>
