<div class="main-container">
  <div *ngIf="examCalendar">
    <form (ngSubmit)="onSubmit()" #calendarForm="ngForm">
      <div class="row">
        <div class="col-7">
          <div class="form-group">
            <label for="course">Kurzus</label>
            <select [compareWith]="compareCourses" class="form-control" id="course"
                    required
                    [(ngModel)]="examCalendar.courseDTO" name="course"
                    #course="ngModel">
              <option *ngFor="let course of courses" [ngValue]="course">{{course.courseName}}</option>
            </select>
            <div [hidden]="course.valid || course.pristine" class="alert alert-danger">
              Kurzus megadása kötelező
            </div>
          </div>

          <div class="form-group">
            <label for="type">Vizsgatípus</label>
            <select [compareWith]="compareTypes" class="form-control" id="type"
                    required
                    [(ngModel)]="examCalendar.examTypeCodeVDTO" name="type"
                    #type="ngModel">
              <option *ngFor="let type of examTypes" [ngValue]="type">{{type.description}}</option>
            </select>
            <div [hidden]="type.valid || type.pristine" class="alert alert-danger">
              Vizsgatípus megadása kötelező
            </div>
          </div>

          <div class="form-group">
            <label for="location">Helyszín</label>
            <select [compareWith]="compareLocations" class="form-control" id="location"
                    required
                    [(ngModel)]="examCalendar.locationCodeVDTO" name="location"
                    #location="ngModel">
              <option *ngFor="let location of examLocations" [ngValue]="location">{{location.description}}</option>
            </select>
            <div [hidden]="location.valid || location.pristine" class="alert alert-danger">
              Helyszín megadása kötelező
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="form-group">
            <label for="startTime">Kezdés</label>
            <input type="text" class="form-control" id="startTime"
                   required
                   [(ngModel)]="examCalendar.startTime" name="startTime"
                   #startTime="ngModel"
                   [owlDateTimeTrigger]="dtStart"
                   [owlDateTime]="dtStart"
                   autocomplete="off">
            <owl-date-time #dtStart></owl-date-time>
            <div [hidden]="startTime.valid || startTime.pristine"
                 class="alert alert-danger">
              A kezdés időpontját meg kell adni!
            </div>
          </div>

          <div class="form-group">
            <label for="endTime">Vége</label>
            <input type="text" class="form-control" id="endTime"
                   required
                   [(ngModel)]="examCalendar.endTime" name="endTime"
                   #endTime="ngModel"
                   [owlDateTimeTrigger]="dtEnd"
                   [owlDateTime]="dtEnd"
                   autocomplete="off">
            <owl-date-time #dtEnd></owl-date-time>
            <div [hidden]="endTime.valid || endTime.pristine"
                 class="alert alert-danger">
              A vizsga végét meg kell adni!
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <hr class="mb-2">
        <button type="submit" class="btn btn-success mr-4" [disabled]="!calendarForm.form.valid">Mentés</button>
        <button class="btn btn-secondary" (click)="reload()">Visszaállítás</button>
      </div>
    </form>
  </div>

  <div class="mt-4">
     <span class="float-right">
      Összesen: {{ dataSource.data.length }}
    </span>
    <h2>
      Jelentkezések
    </h2>
  </div>
  <div class="mat-elevation-z8 mt-4">
    <table mat-table [dataSource]="dataSource" matSort class="w-100">

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Felhasználónév </th>
        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Regisztráció státusza </th>
        <td mat-cell *matCellDef="let element"> {{mapStatusToLocalizedString(element.status)}} </td>
      </ng-container>

      <ng-container matColumnDef="registrationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Regisztráció dátuma </th>
        <td mat-cell *matCellDef="let element"> {{element.registrationDate | date:'yyyy.MM.dd' }} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Műveletek </th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.status !== registrationStatuses.AcceptedRegistration" type="button"
                  class="btn btn-sm btn-success mr-4"
                  (click)="acceptRegistration($event, element.registrationId)">
            Jóváhagy
          </button>
          <button *ngIf="element.status !== registrationStatuses.Cancelled"
                  type="button"
                  class="btn btn-sm btn-danger"
                  (click)="rejectRegistration($event, element.registrationId)">
            Elutasít
          </button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="vizenjo-row" routerLink="/students/{{ row.studentId }}" [queryParams]="{ examId: examId }"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</div>
