import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../network/services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() topTitle: string;
  @Input() pageTitle: string;

  @Input() onDashboard: boolean;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.auth.logout();
    this.router.navigate(['vizenjo']);
  }

}
