import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ExamCalendar} from '../../network/models/ExamCalendar';
import {ExamRegistration} from '../../network/models/ExamRegistration';
import {NetworkService} from '../../network/services/network.service';
import {ExamType} from '../../network/models/ExamType';
import {CalendarCourse} from '../../network/models/CalendarCourse';
import {Location} from '../../network/models/Location';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {RegistrationTableRow} from '../../network/models/RegistrationTableRow';
import {RegistrationStatuses} from './enums/RegistrationStatuses';
import {ModifyExamCalendar} from '../../network/models/ModifyExamCalendar';
import {NotificationService} from '../../notification/notification.service';
import {NotificationType} from '../../notification/NotificationType';

@Component({
  selector: 'app-exam-details',
  templateUrl: './exam-details.component.html',
  styleUrls: ['./exam-details.component.scss']
})
export class ExamDetailsComponent implements OnInit, AfterViewInit {
  registrationStatuses = RegistrationStatuses;

  examId: number;

  examCalendar: ExamCalendar;
  examRegistrations: ExamRegistration[];

  examTypes: ExamType[];
  courses: CalendarCourse[];
  examLocations: Location[];

  displayedColumns: string[] = ['username', 'status', 'registrationDate', 'actions'];
  dataSource = new MatTableDataSource<RegistrationTableRow>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private route: ActivatedRoute, private network: NetworkService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.examId = this.route.snapshot.params.id;

    this.network.getExamTime(this.examId).subscribe( examCalendar => {
      this.examCalendar = examCalendar;
      this.getRegistrations(this.examCalendar.examCalendarId);
    });

    this.network.getExamRegistrations(this.examId).subscribe( examRegistrations => {
      this.examRegistrations = examRegistrations;
    });

    this.network.getExamTypes().subscribe(examTypes => {
      this.examTypes = [];
      examTypes.forEach( examType => {
        this.examTypes.push({ codeId: examType.codeId, codeValue: examType.codeValue, description: examType.description });
      });
    });

    this.network.getCourses().subscribe( courses => {
      this.courses = [];
      courses.content.forEach(course => {
        this.courses.push({ courseId: course.courseId, courseName: course.courseName });
      });
    });

    this.network.getExamLocations().subscribe( locations => {
      this.examLocations = [];
      locations.forEach(location => {
        this.examLocations.push({ codeId: location.codeId, codeValue: location.codeValue, description: location.description });
      });
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getRegistrations(examCalendarId: number): void {
    this.network.getExamRegistrations(examCalendarId).subscribe( registrations => {
      const tableRows = [];
      registrations.forEach(registration => {
        const tableRow = new RegistrationTableRow(
            registration.studentDTO.username,
            registration.registrationStatusCodeVDTO.codeValue,
            registration.registrationDate,
            registration.studentDTO.studentId,
            registration.examRegistrationId
          );
        tableRows.push(tableRow);
      });
      this.dataSource.data = tableRows;
    });
  }

  acceptRegistration(event, examRegistrationId: number): void {
    event.stopPropagation();
    this.network.acceptRegistration(examRegistrationId).subscribe( () => {
      this.getRegistrations(this.examCalendar.examCalendarId);
    });
  }

  rejectRegistration(event, examRegistrationId: number): void {
    event.stopPropagation();
    this.network.rejectRegistration(examRegistrationId).subscribe( () => {
      this.getRegistrations(this.examCalendar.examCalendarId);
    });
  }

  onSubmit(): void {
    const examTypeCodeId =
      this.examTypes.find( examType => examType.codeValue === this.examCalendar.examTypeCodeVDTO.codeValue).codeId;
    const locationCodeId =
      this.examLocations.find( location => location.codeValue === this.examCalendar.locationCodeVDTO.codeValue).codeId;

    const newExamCalendar = new ModifyExamCalendar(
      this.examCalendar.courseDTO.courseId,
      examTypeCodeId,
      locationCodeId,
      this.examCalendar.startTime,
      this.examCalendar.endTime
    );

    this.network.modifyExamTime(this.examCalendar.examCalendarId, newExamCalendar).subscribe( () => {
      this.notificationService.sendMessage({
        message: 'Vizsga sikeresem módosítva',
        type: NotificationType.success
      });
    });
  }

  compareCourses(a, b): boolean {
    if (a && b) {
      return a.courseId === b.courseId;
    }
    return false;
  }

  compareTypes(a, b): boolean {
    if (a && b) {
      return a.codeValue === b.codeValue;
    }
    return false;
  }

  compareLocations(a, b): boolean {
    if (a && b) {
      return a.codeValue === b.codeValue;
    }
    return false;
  }

  reload(): void {
    window.location.reload();
  }

  mapStatusToLocalizedString(status: string): string {
    switch (status) {
      case RegistrationStatuses.Registered:
        return 'Regisztrált';
        break;
      case RegistrationStatuses.AcceptedRegistration:
        return 'Regisztráció elfogadva';
        break;
      case RegistrationStatuses.Cancelled:
        return 'Regisztráció elutasítva';
        break;
      default:
        return 'Ismeretlen státusz';
        break;
    }
  }

}
