import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ExamDashboardComponent} from './components/exam-dashboard/exam-dashboard.component';
import {MainComponent} from './components/main/main.component';
import {ExamDetailsComponent} from './components/exam-details/exam-details.component';
import {CreateExamComponent} from './components/create-exam/create-exam.component';
import {StudentDetailsComponent} from './components/student-details/student-details.component';
import {RedirectGuard} from './network/guards/redirect.guard';
import {AuthGuard} from './network/guards/auth-guard.service';
import {EduMaterialComponent} from './components/edu-material/edu-material.component';
import {QuestionDetailsComponent} from './components/question-details/question-details.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'students/:id', component: StudentDetailsComponent, canActivate: [AuthGuard]},
  { path: 'exams', component: ExamDashboardComponent, canActivate: [AuthGuard]},
  { path: 'exams/:id', component: ExamDetailsComponent, canActivate: [AuthGuard]},
  { path: 'new-exam', component: CreateExamComponent, canActivate: [AuthGuard]},
  // { path: 'edu-material', component: EduMaterialComponent, canActivate: [AuthGuard]},
  { path: 'questions', component: QuestionDetailsComponent, canActivate: [AuthGuard]},
  {
    path: 'vizenjo',
    canActivate: [RedirectGuard],
    component: MainComponent,
    data: {
      externalUrl: 'https://vizenjo.hu/'
    }
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
