import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MainComponent } from './components/main/main.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {DatePipe} from '@angular/common';
import { ExamDashboardComponent } from './components/exam-dashboard/exam-dashboard.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './network/interceptors/token.interceptor';
import {AuthGuard} from './network/guards/auth-guard.service';
import {RedirectGuard} from './network/guards/redirect.guard';
import {ToastrModule} from 'ngx-toastr';
import {MatSortModule} from '@angular/material/sort';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ExamDetailsComponent } from './components/exam-details/exam-details.component';
import {FormsModule} from '@angular/forms';
import { CreateExamComponent } from './components/create-exam/create-exam.component';
import {DateTimeAdapter, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {MyOwlDateTimeIntl} from './network/models/MyOwlDateTimeIntl';
import { StudentDetailsComponent } from './components/student-details/student-details.component';
import { EduMaterialComponent } from './components/edu-material/edu-material.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {AngularEditorModule} from '@kolkov/angular-editor';
import { QuestionDetailsComponent } from './components/question-details/question-details.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SideNavComponent,
    NavbarComponent,
    ExamDashboardComponent,
    ExamDetailsComponent,
    CreateExamComponent,
    StudentDetailsComponent,
    EduMaterialComponent,
    QuestionDetailsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatSidenavModule,
    FontAwesomeModule,
    MDBBootstrapModule.forRoot(),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    MatFormFieldModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CKEditorModule,
    AngularEditorModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuard,
    RedirectGuard,
    DatePipe,
    {provide: OwlDateTimeIntl, useClass: MyOwlDateTimeIntl}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(dateTimeAdapter: DateTimeAdapter<any>) {
    dateTimeAdapter.setLocale('hu-HU');
  }
}
