import { Component, OnInit } from '@angular/core';
import { Question } from 'src/app/network/models/Question';
import { NetworkService } from 'src/app/network/services/network.service';

@Component({
  selector: 'app-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.scss']
})
export class QuestionDetailsComponent implements OnInit {

  questionId?: number;
  question?: Question;
  imageToShow?: any;

  constructor(private network: NetworkService) { }

  ngOnInit(): void {
  }

  submit(): void {
    this.imageToShow = null;
    this.network.getQuestion(this.questionId).subscribe(q => {
      this.question = q;
      if (q.picture)
      {
        this.network.getImageResource(q.picture.resourceId).subscribe(image => {
          this.createImageFromBlob(image);
        });
      }
    });
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
