import {CalendarCourse} from './CalendarCourse';
import {ExamType} from './ExamType';
import {Location} from './Location';

export class ExamCalendar {
  examCalendarId:	number;
  courseDTO:	CalendarCourse;
  examTypeCodeVDTO:	ExamType;
  locationCodeVDTO:	Location;
  startTime:	Date;
  endTime:	Date;
}
