import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {fromEvent, Subscription} from 'rxjs';
import {NetworkService} from '../../network/services/network.service';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {ActiveStudentTableRow} from '../../network/models/ActiveStudentTableRow';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../network/services/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['username', 'registrationDate', 'studentName'];
  dataSource = new MatTableDataSource<ActiveStudentTableRow>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;
  inputSubscription: Subscription;

  constructor(private network: NetworkService,
              private route: ActivatedRoute,
              private auth: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    const token = this.route.snapshot.queryParams.token;
    if (token) {
      this.network.getOAuthToken(token).subscribe(oAuth => {
        this.auth.login(oAuth);
        this.router.navigate(
          ['']
        ).then(() => {
          this.getActiveStudentsIfLoggedIn();
        });
      });
    } else {
      this.getActiveStudentsIfLoggedIn();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.inputSubscription = fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(350),
        distinctUntilChanged(),
        tap((text) => {
          this.applyFilter(this.input.nativeElement.value);
        })
      )
      .subscribe();
  }

  getActiveStudentsIfLoggedIn(): void {
    this.auth.isLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.router.navigate(['vizenjo']);
      } else {
        this.network.getActiveStudents().subscribe( students => {
          const tableRows = [];
          students.content.forEach(student => {
            const tableRow = new ActiveStudentTableRow(
              student.studentDTO.username,
              student.studentDTO.registrationDate,
              student.studentDTO.studentName,
              student.studentDTO.studentId,
              student.studentCourseId
            );
            tableRows.push(tableRow);
          });
          this.dataSource.data = tableRows;
        });
      }
    });
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  ngOnDestroy(): void {
    if (this.inputSubscription) {
      this.inputSubscription.unsubscribe();
    }
  }


}
