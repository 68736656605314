<div class="main-container">
  <div *ngIf="examId" class="float-right back-to-exam" routerLink="/exams/{{examId}}">Vissza a vizsgához</div>
  <div class="row">
    <div class="col-4 border-right">
      <h2>
        Alapadatok
      </h2>
      <div *ngIf="student">
        <label for="name">Név</label>
        <h3 class="font-weight-bold" id="name">
          {{ student.studentDTO.studentName }}
        </h3>
        <label for="email">Email</label>
        <h3 class="font-weight-bold" id="email">
          {{ student.studentDTO.username }}
        </h3>
        <label for="registrationDate">Regisztráció</label>
        <h3 class="font-weight-bold" id="registrationDate">
          {{ student.studentDTO.registrationDate | date:'yyyy.MM.dd HH:mm' }}
        </h3>
      </div>
    </div>
    <div class="col-8">
      <h2 class="ml-4">
        Feltöltött Dokumentumok
      </h2>
      <div class="row ml-4">
        <div class="col-6">
          <label for="idCardPic">Személyi igazolvány eleje</label>
          <div *ngIf="idCardPicture else noIdCardPicture">
            <a [href]="idCardPicture" download>
              <img class="image-container" [src]="idCardPicture" alt="id card" id="idCardPic">
            </a>
          </div>
          <ng-template #noIdCardPicture>
            <div>
              <h3 class="font-weight-bold" style="color: red;">
                Nincs feltöltött személyi igazolvány
              </h3>
            </div>
          </ng-template>
        </div>
        <div class="col-6">
          <label for="addressCardPic">Lakcímkártya hátulja</label>
          <div *ngIf="idCardPicture else noAddressCardPicture" >
            <a [href]="addressCardPicture" download>
              <img class="image-container" [src]="addressCardPicture" alt="address card" id="addressCardPic">
            </a>
          </div>
          <ng-template #noAddressCardPicture>
            <div>
              <h3 class="font-weight-bold" style="color: red;">
                Nincs feltöltött lakcímkártya
              </h3>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <hr class="mb-2">
  <div class="mt-4">
    <span class="float-right">
      <span *ngIf="allowedToTakeExam else notAllowedToTakeExam" class="allowed-rectangle">
        Mehet vizsgázni!
      </span>
      <ng-template #notAllowedToTakeExam>
        <span class="not-allowed-rectangle">
          Nem mehet vizsgázni!
        </span>
      </ng-template>
    </span>
    <h2>
      Haladás
    </h2>
  </div>
  <div class="mat-elevation-z8 mt-4">
    <table mat-table [dataSource]="progressDataSource" matSort class="w-100">

      <ng-container matColumnDef="topicName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Topik </th>
        <td mat-cell *matCellDef="let element" class="font-weight-bold"> {{element.topicName}} </td>
      </ng-container>

      <ng-container matColumnDef="learnedPercent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Haladás </th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.learnedPercent < 80 ? 'red-color' : ''"> {{ element.learnedPercent }}% </td>
      </ng-container>

      <ng-container matColumnDef="numOfQuestions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Kérdések </th>
        <td mat-cell *matCellDef="let element"> {{ element.numOfQuestions }} </td>
      </ng-container>

      <ng-container matColumnDef="numOfLearnedQuestions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Megtanult Kérdések </th>
        <td mat-cell *matCellDef="let element"> {{ element.numOfLearnedQuestions }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="progressDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: progressDisplayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</div>
